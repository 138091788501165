import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import I18n, { localize, i18n } from 'locale/I18n'
import MediaContext from 'util/MediaContext'
import Svg from 'components/common/Svg';
import Path from 'util/PathUtil'

class Thumbnail extends PureComponent {
    static contextType = MediaContext
    
    onClick = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onClick(this.props.id);
    }

    renderGuestName = guests => {
        if (!guests || 0===guests.length) return null;
        if (guests.length > 1) return <I18n $="media.multiple_flyers" />
        
        let { name } = guests[0];
        if (name==='UNASSIGNED') return <I18n $="media.unassigned" /> 
            
        return name;
    }

    onAnchorClick = evt => {
        evt.stopPropagation(); // Prevent the click from bubbling up to the parent div
        // Do not call preventDefault here, so the href will be followed
    }
    
    render() {
        let { url, selected, rankValue, isHighFlight, isLoading, mediaUrl, downloadable, guests } = this.props;

        return (
            <div className='thumbnail-container'>
                <div data-rank={rankValue} className="thumb" onClick={this.onClick} title={i18n('media.show_fullsize')}>
                    <figure style={{ backgroundImage: `url(${url})` }}></figure>
                    {
                        selected &&
                        <i className="selected">
                            <Svg id="ic-checkmark" title={i18n('media.selected')}/>
                        </i>
                    }
                    {
                        isHighFlight &&
                        <i className="highflight"></i>
                    }
                    {
                        !isLoading && this.props.onRemove &&
                        <button className="removeCartX" onClick={this.props.onRemove} data-id={this.props.id}><Svg id="ic-close"/></button>
                    }
                    {
                        !isLoading && downloadable &&
                        <a className="download-link" href={Path.getDownloadUrl(mediaUrl)} onClick={this.onAnchorClick}>
                            <Svg id="download"/>
                        </a>
                    }
                </div>
                {
                    !isLoading && downloadable &&
                    <p className='thumbnail-guest'>{this.renderGuestName(guests)}</p>
                }
            </div>
        )
    }
    
    static propTypes = {
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
        isHighFlight: PropTypes.bool,
        selected: PropTypes.bool,
        onRemove: PropTypes.func,
        isLoading: PropTypes.bool,
        mediaUrl: PropTypes.string,
        downloadable: PropTypes.bool,
    }
}

export default localize(Thumbnail);